'use client';

import { PostHogProvider, usePostHog } from 'posthog-js/react';
import posthog from 'posthog-js';
import { usePathname, useSearchParams } from 'next/navigation';
import React, { useEffect } from 'react';
import { queryClient } from '@howl/shared-utils/clients/react-query-client';
import { QueryClientProvider } from '@tanstack/react-query';
import { env } from '../env';
import { ClerkProvider, useOrganization, useUser } from '@clerk/nextjs';
import { useProfile } from './hooks/use-profile';

if (typeof window !== 'undefined') {
  posthog.init(env.NEXT_PUBLIC_POSTHOG_KEY ?? '', {
    api_host: env.NEXT_PUBLIC_POSTHOG_HOST,
    ui_host: 'https://us.posthog.com',
    person_profiles: 'identified_only',
    capture_pageview: false, // Disable automatic pageview capture, as we capture manually
    capture_pageleave: true, // Enable automatic pageleave capture
    // Enable debug mode in development
    // loaded: (posthog) => {
    //   if (process.env.NODE_ENV === 'development') posthog.debug();
    // },
  });
}

function PostHogPageView() {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const postHogClient = usePostHog();
  // Track pageviews
  useEffect(() => {
    if (pathname && postHogClient) {
      let url = window.origin + pathname;
      if (searchParams.toString()) {
        url = url + `?${searchParams.toString()}`;
      }
      postHogClient.capture('$pageview', {
        $current_url: url,
      });
    }
  }, [pathname, searchParams, postHogClient]);

  return null;
}

const PostHogUser = () => {
  const { user } = useUser();
  const { organization } = useOrganization();
  const { profile } = useProfile();
  useEffect(() => {
    if (user) {
      posthog.identify(user.id, {
        email: user?.primaryEmailAddress?.emailAddress ?? null,
        name: user.fullName,
        activeProfileId: user.publicMetadata.activeProfile ?? null,
        activeOrgId: user.publicMetadata.activeOrgId ?? null,
      });
    }
  }, [user]);

  useEffect(() => {
    if (organization) {
      posthog.group('organization', organization.id, {
        name: organization.name,
      });
    }
  }, [organization]);

  useEffect(() => {
    if (profile) {
      posthog.group('profile', profile.id, {
        name: profile.name,
        type: profile.type,
      });
    }
  }, [profile]);

  return null;
};

export function Providers({ children }: { children: React.ReactNode }) {
  const pathname = usePathname();
  const formButtonPrimaryText =
    pathname === '/sign-in' ? 'Sign In' : 'Continue';

  const localization = {
    signIn: {
      start: {
        title: 'Welcome to Howl',
        subtitle: '',
        actionText: 'New to Howl?',
      },
    },
    formButtonPrimary: formButtonPrimaryText,
    signUp: {
      start: {
        title: 'Welcome to Howl',
        subtitle: '',
        actionText: 'Already have an account?',
      },
    },
  };

  return (
    <ClerkProvider
      localization={localization}
      appearance={{
        variables: {
          colorDanger: '#fa4e0a',
          colorSuccess: '#dfff75',
          colorWarning: '#fa4e0a',
          borderRadius: '0.5rem',
          colorTextSecondary: 'rgba(0, 0, 0, 0.60)',
          colorText: 'rgba(0, 0, 0)',
          spacingUnit: '1em',
          fontFamily: 'var(--font-sans)',
          colorPrimary: '#00331e',
          colorTextOnPrimaryBackground: '#000000',
        },
        elements: {
          logoBox: 'mx-auto flex items-center justify-center w-full',
          formButtonReset:
            'bg-interaction-tertiary hover:bg-interaction-tertiary-hover rounded-full px-4 py-2',
          formButtonPrimary:
            'rounded-full font-medium bg-interaction-primary text-text-primary hover:bg-interaction-primary-hover px-4 py-2',
        },
        layout: {
          showOptionalFields: true,
          socialButtonsVariant: 'iconButton',
        },
      }}
    >
      <PostHogProvider client={posthog}>
        <PostHogPageView />
        <PostHogUser />
        <QueryClientProvider client={queryClient}>
          {children}
        </QueryClientProvider>
      </PostHogProvider>
    </ClerkProvider>
  );
}
