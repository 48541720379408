import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/vercel/path0/apps/web/src/app/providers.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/src/styles/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["TRPCReactProvider"] */ "/vercel/path0/apps/web/src/trpc/react.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/libs/shared/ui-kit/src/v3/toaster/toaster.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Instrument_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/BallPill-light.otf\",\"display\":\"swap\",\"variable\":\"--font-ball-pill\"}],\"variableName\":\"ballPill\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/FoundryDit-Medium.otf\",\"display\":\"swap\",\"variable\":\"--font-foundry-dit\"}],\"variableName\":\"foundryDit\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./fonts/FragmentMono-Regular.ttf\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"./fonts/FragmentMono-Italic.ttf\",\"weight\":\"400\",\"style\":\"italic\"}],\"display\":\"swap\",\"variable\":\"--font-fragment-mono\"}],\"variableName\":\"fragmentMono\"}");
