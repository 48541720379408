'use client';

import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from '../toast';
import { useToast } from './use-toast';
import {
  StatusIconChat,
  StatusIconCritical,
  StatusIconDelete,
  StatusIconDownload,
  StatusIconImage,
  StatusIconPause,
  StatusIconRemove,
  StatusIconResume,
  StatusIconSuccess,
  StatusIconWarning,
} from '../status/';

export function Toaster() {
  const { toasts } = useToast();

  return (
    <ToastProvider>
      {toasts.map(function ({ id, title, description, action, ...props }) {
        let icon;

        switch (props.variant) {
          case 'success':
            icon = <StatusIconSuccess />;
            break;
          case 'warning':
            icon = <StatusIconWarning />;
            break;
          case 'critical':
            icon = <StatusIconCritical />;
            break;
          case 'remove':
            icon = <StatusIconRemove />;
            break;
          case 'download':
            icon = <StatusIconDownload />;
            break;
          case 'pause':
            icon = <StatusIconPause />;
            break;
          case 'delete':
            icon = <StatusIconDelete />;
            break;
          case 'chat':
            icon = <StatusIconChat />;
            break;
          case 'resume':
            icon = <StatusIconResume />;
            break;
          case 'image':
            if (props.leadingProp) {
              icon = <StatusIconImage leadingProp={props.leadingProp} />;
            }
            break;
          default:
            icon = null;
        }

        return (
          <Toast key={id} {...props}>
            <div>{icon}</div>
            <div className="grid md:w-9/12 w-2/3 my-auto">
              {title && <ToastTitle>{title}</ToastTitle>}
              {description && (
                <ToastDescription>{description}</ToastDescription>
              )}
              {action}
            </div>
            <ToastClose />
          </Toast>
        );
      })}
      <ToastViewport />
    </ToastProvider>
  );
}
