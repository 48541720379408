import { useAction } from '@howl/shared-utils/hooks/use-action';
import { getActiveProfileAction } from '../actions/get-active-profile';
import { useEffect, useState } from 'react';
import { useUser } from '@clerk/nextjs';

export const useProfile = () => {
  const { result, execute } = useAction(getActiveProfileAction);
  const [profile, setProfile] = useState<Awaited<
    ReturnType<typeof getActiveProfileAction>
  > | null>(null);
  const { user } = useUser();

  useEffect(() => {
    if (result && result.id) {
      setProfile(result);
    }
  }, [result]);

  useEffect(() => {
    if (user) {
      execute();
    }
  }, [user]);

  return {
    profile,
  };
};
