import { useState, useCallback } from 'react';

// Adjust the hook to accept a generic async function and its arguments
export function useAction<T, A extends any[]>(
  asyncFunction: (...args: A) => Promise<T>,
): {
  isLoading: boolean;
  isError: boolean;
  result: T | null;
  execute: (...args: A) => Promise<T | undefined>; // Allow execute to accept arguments
} {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [result, setResult] = useState<T | null>(null);

  // Use useCallback to memoize the execute function
  const execute = useCallback(
    async (...args: A) => {
      setIsLoading(true);
      setIsError(false);
      try {
        const data = await asyncFunction(...args); // Pass the arguments to the async function
        setResult(data);
        setIsError(false);
        return data;
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    },
    [asyncFunction],
  ); // Re-create the function if asyncFunction changes

  return { isLoading, isError, result, execute };
}
